import React from 'react'
import {Tooltip} from 'antd'
// const Search = Input.Search;
const Search=Loader.loadBaseComponent('SearchInput');

export default ({
  searchData,
  onChange
}) => {
  return (
    <div className='org-wrapper-search'>
      <div className='search-group'>
        <Tooltip title="请输入组织名称查询">
          <Search
            placeholder="请输入组织名称查询"
            enterButton
            onChange={value => onChange({keywords:value, offset: 0})}
            defaultValue={searchData.keywords}
          />
        </Tooltip>
      </div>
    </div>
  )
}