/**
 * @desc 分配资源小区分配
 * @author wwj
 */

/**
 * @desc state
 * @param {Array} list 未分配小区列表
 * @param {Array} currentList 已分配小区列表
 * @param {Array} checkedIds 未分配小区选中id
 * @param {Array} cancelCheckedIds 已分配小区选中id
 * @param {Boolean} loading 加载状态
 * @param {Object} searchData 未分配查询条件
 * @param {Object} currentSearchData 已分配查询条件
 * @param {String} placeIds 选中场所id
 */

import React from "react";
import { Button, Spin, message, Checkbox } from "antd";
import Table from "./components/Table";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");

@withRouter
@Decorator.businessProvider("place", "organization")
class AreResource extends React.Component {
  constructor(props) {
    super(props);
    this.refTable = React.createRef();
    this.refTableRight = React.createRef();
    this.state = {
      list: [],
      currentList: [],
      checkedIds: [],
      cancelCheckedIds: [],
      loading: true,
      keywords: []
    };
  }

  componentDidMount() {
    this.updateAllData();
  }

  /**
   * @desc 修改查询条件 searchData:未分配小区 currentSearchData：已分配小区
   */
  updateSearchData = (type = "searchData", options) => {
    const keywords = this.state.keywords;
    const index = type === "searchData" ? 0 : 1;
    keywords[index] = options.keywords;
    this.setState({ keywords });
  };

  /**
   * @desc 综合同步更新两侧数据
   */
  updateAllData = async () => {
    this.setState({ loading: true });
    try {
      const result = await Promise.all([this.queryAreas(), this.queryAreas("currentSearchData")]);
      let list = result[0].data.list;
      let currentList = result[1].data.list;
      this.setState({
        list,
        currentList,
        loading: false
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * @desc 查询小区 searchData: 未分配 currentSearchData:已分配
   */
  queryAreas = (type = "searchData") => {
    const { data } = this.props;
    let searchData = {
      offset: 0,
      limit: 100000,
      incomingOrganizationId: type == "searchData" ? data.parentId : data.id,
      containSuborganization: false
    };
    return Service.community.queryVillages(searchData);
  };

  /**
   * @desc 分配小区到应用系统/从应用系统解除分配 type update: 分配 cancel : 取消分配
   */
  updateOrgAreas = async (type = "update") => {
    const { data, organization } = this.props;
    const { checkedIds, cancelCheckedIds, list, currentList } = this.state;
    const parantOrg = organization.getOrgInfoByOrgId(data.parentId);
    const options = {
      targetOrganizationId: null,
      villageIds: null
    };
    const info = {
      areaName: [],
      fromOrgName: null,
      toOrgName: null
    };
    const newState = {};
    if (type == "update") {
      const checkedList = list.filter(v => checkedIds.indexOf(v.id) > -1);
      info.areaName = checkedList.map(v => v.villageName);
      info.fromOrgName = parantOrg.name;
      info.toOrgName = data.organizationName;

      options.targetOrganizationId = data.id;
      options.villageIds = checkedIds;

      newState.list = list.filter(v => checkedIds.indexOf(v.id) === -1);
      newState.currentList = [].concat(currentList, checkedList);
      newState.checkedIds = [];
    } else {
      const checkedList = currentList.filter(v => cancelCheckedIds.indexOf(v.id) > -1);
      info.areaName = checkedList.map(v => v.villageName);
      info.fromOrgName = data.organizationName;
      info.toOrgName = parantOrg.name;

      options.targetOrganizationId = data.parentId;
      options.villageIds = cancelCheckedIds;

      newState.currentList = currentList.filter(v => cancelCheckedIds.indexOf(v.id) === -1);
      newState.list = [].concat(list, checkedList);
      newState.cancelCheckedIds = [];
    }

    this.setState({ loading: true });

    try {
      await Service.community.assignViillage(options, info);
      message.success("操作成功");
      this.setState(newState);
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * @desc 未分配小区选择
   */
  onChecked = async checkedIds => {
    return await this.setState({ checkedIds });
  };

  allocatedAll = e => {
    let { list, keywords } = this.state;
    list = keywords[0] ? list.filter(v => v.villageName.indexOf(keywords[0]) > -1) : list;
    let checkedIds = e.target.checked ? list.map(v => v.id) : [];
    this.onChecked(checkedIds).then(() => {
      this.refTable.current.forceUpdateGrid();
    });
  };

  /**
   * @desc 已分配小区选择
   */
  onCancelChecked = async cancelCheckedIds => {
    return await this.setState({ cancelCheckedIds });
  };

  unAllocatedAll = e => {
    const { currentList, keywords } = this.state;
    const list = keywords[1] ? currentList.filter(v => v.villageName.indexOf(keywords[1]) > -1) : currentList;
    this.onCancelChecked(e.target.checked ? list.map(v => v.id) : []).then(() => {
      this.refTableRight.current.forceUpdateGrid();
    });
  };

  render() {
    const { list, currentList, checkedIds, cancelCheckedIds, loading, keywords } = this.state;
    const [keywords1, keywords2] = keywords;
    const listPart1 = keywords1 ? list.filter(v => v.villageName.indexOf(keywords1) > -1) : list;
    const listPart2 = keywords2 ? currentList.filter(v => v.villageName.indexOf(keywords2) > -1) : currentList;
    return (
      <div className="org-area-resource-container">
        <Spin spinning={loading}>
          <div className="area-edit-container">
            <div className="org-area-table-left">
              <span className="device-tlt">全部场所：</span>
              <div className="tb">
                <Table
                  list={list}
                  ref={this.refTable}
                  checkedIds={checkedIds}
                  onChecked={this.onChecked}
                  onChange={this.updateSearchData}
                  type="searchData"
                >
                  <div className="item th">
                    <div className="name">
                      <Checkbox
                        checked={listPart1.length === checkedIds.length && checkedIds.length > 0}
                        indeterminate={listPart1.length !== checkedIds.length && checkedIds.length > 0}
                        onClick={this.allocatedAll}
                      />
                      小区列表
                    </div>
                  </div>
                </Table>
              </div>
            </div>
            <div className="btn-group">
              <div>
                <Button type="primary" disabled={!!!checkedIds.length} onClick={() => this.updateOrgAreas()}>
                  <IconFont type="icon-S_Arrow_BigRight" />
                  <div>分配</div>
                </Button>
                <Button disabled={!!!cancelCheckedIds.length} onClick={() => this.updateOrgAreas("cancel")}>
                  <IconFont type="icon-S_Arrow_BigLeft" />
                  <div>取消</div>
                </Button>
              </div>
            </div>
            <div className="org-area-table-right">
              <span className="device-tlt">已分配小区（{currentList && currentList.length}个）</span>
              <div className="tb">
                <Table
                  list={currentList}
                  ref={this.refTableRight}
                  checkedIds={cancelCheckedIds}
                  onChecked={this.onCancelChecked}
                  onChange={this.updateSearchData}
                  type="currentSearchData"
                >
                  <div className="item th">
                    <div className="name">
                      <Checkbox
                        checked={listPart2.length === cancelCheckedIds.length && cancelCheckedIds.length > 0}
                        indeterminate={listPart2.length !== cancelCheckedIds.length && cancelCheckedIds.length > 0}
                        onClick={this.unAllocatedAll}
                      />
                      小区列表
                    </div>
                  </div>
                </Table>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default AreResource;
