import React from "react";
import "./index.less";
import ResourceButtons from "./components/resourceButtons";

const ModalShow = Loader.loadBaseComponent("ModalComponent");

class ResourceList extends React.Component {
  render() {
    const { item, showResourceList, cancleResource, allocateResource } = this.props;
    return (
      <ModalShow
        title={"分配资源"}
        visible={showResourceList}
        otherModalFooter={<></>}
        onCancel={cancleResource}
        onOk={allocateResource}
        width={952}
        wrapClassName="org-resource-modal-wrapper"
        destroyOnClose={true}
      >
        <ResourceButtons data={item} />
      </ModalShow>
    );
  }
}

export default ResourceList;
