(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("_"), require("ReactRouterDOM"), require("mobxReact"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define("organization", ["React", "antd", "_", "ReactRouterDOM", "mobxReact", "moment"], factory);
	else if(typeof exports === 'object')
		exports["organization"] = factory(require("React"), require("antd"), require("_"), require("ReactRouterDOM"), require("mobxReact"), require("moment"));
	else
		root["organization"] = factory(root["React"], root["antd"], root["_"], root["ReactRouterDOM"], root["mobxReact"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 