import React from "react";
import DeviceResource from "./deviceResource/";
import PlaceResource from "./placeResource/";
import AreaResource from "./areResource/";
import { Button } from "antd";
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");

class ResourceButtons extends React.Component {
  componentDidMount = () => {
    this.goResourceList("devices");
  };
  state = {
    resourceContent: null,
    buttonKey: Math.random()
  };
  goResourceList = type => {
    const { data } = this.props;
    const { buttonKey } = this.state;
    let resourceContent = null;
    this.setState({
      buttonKey: Math.random()
    });
    this.defaultDeviceClass = "";
    this.defaultPlaceClass = "";
    this.defaultAreaClass = "";
    switch (type) {
      case "devices":
        this.defaultDeviceClass = "active";
        resourceContent = <DeviceResource data={data} key={buttonKey} />;
        break;
      case "places":
        this.defaultPlaceClass = "active";
        resourceContent = <PlaceResource data={data} key={buttonKey} />;
        break;
      case "areas":
        this.defaultAreaClass = "active";
        resourceContent = <AreaResource data={data} key={buttonKey} />;
        break;
      default:
        break;
    }
    this.setState({
      resourceContent
    });
  };
  render() {
    const { resourceContent } = this.state;
    return (
      <>
        <div className="org-resource-buttons">
          <Button onClick={() => this.goResourceList("devices")} icon={"plus"} className={this.defaultDeviceClass}>
            分配设备
          </Button>
          {BSConfig.microService.place && (
            <Button onClick={() => this.goResourceList("places")} icon={"plus"} className={this.defaultPlaceClass}>
              分配场所
            </Button>
          )}
          <AuthComponent actionName="villageModify">
            <Button onClick={() => this.goResourceList("areas")} icon={"plus"} className={this.defaultAreaClass}>
              分配小区
            </Button>
          </AuthComponent>
        </div>
        {resourceContent}
      </>
    );
  }
}

export default ResourceButtons;
