/**
 * @desc 分配资源设备分配
 * @author wwj
 */

/**
 * @desc state
 * @param {Array} list 未分配设备列表
 * @param {Array} currentList 已分配设备列表
 * @param {Array} checkedIds 未分配设备选中id
 * @param {Array} cancelCheckedIds 已分配设备选中id
 * @param {Boolean} loading 加载状态
 * @param {Object} searchData 未分配查询条件
 * @param {Object} currentSearchData 已分配查询条件
 * @param {String} placeIds 选中场所id
 */

import React from "react";
import { Button, Spin, message, Checkbox } from "antd";
import Table from "./components/Table";
import PlaceTree from "./components/PlaceTree";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { uniq, uniqBy } from "lodash";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");

@withRouter
@Decorator.businessProvider("place")
class DeviceResource extends React.Component {
  constructor(props) {
    super(props);
    this.refTable = React.createRef();
    this.refTableRight = React.createRef();
    this.state = {
      list: [],
      currentList: [],
      deviceList: [],
      treeData: [],
      checkedIds: [],
      cancelCheckedIds: [],
      loading: true,
      keywords: [],
      placeIds: [],
      tabKey: Math.random()
    };
  }

  componentWillUnmount() {
    this.refTable = null;
    this.refTableRight = null;
  }
  componentDidMount() {
    this.updateAllData();
  }

  /**
   * @desc 修改查询条件 searchData:未分配设备 currentSearchData：已分配设备
   */
  updateSearchData = (type = "searchData", options) => {
    const keywords = this.state.keywords;
    const index = type === "searchData" ? 0 : 1;
    keywords[index] = options.keywords;
    this.setState({ keywords });
  };

  /**
   * @desc 综合同步更新两侧数据
   */
  updateAllData = async () => {
    const { place } = this.props;
    this.setState({ loading: true });
    try {
      const { db } = Dict.map;
      const result = await Promise.all([this.queryDevices(), this.queryDevices("currentSearchData")]);
      const deviceList = result[0].data.list.filter(v => v.deviceType !== db.value);
      const currentList = result[1].data.list.filter(v => v.deviceType !== db.value);
      let placeIds = [];
      deviceList.forEach(v => {
        if (v.placeId) {
          placeIds.push(v.placeId);
        }
      });
      const placeCodes = place.getPlaceInfoByIds(uniq(placeIds)).map(v => v.id);
      const placeList = uniqBy(place.getParentPlaceListByIds(placeCodes), "id");
      this.setState({
        list: deviceList,
        deviceList,
        treeData: Utils.computPlaceTree(placeList),
        currentList
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        loading: false
      });
    }
  };
  /**
   * @desc 查询设备 searchData: 未分配 currentSearchData:已分配
   */
  queryDevices = (type = "searchData") => {
    const { data } = this.props;
    let searchData = {
      queryType: 2,
      orgIds: type == "searchData" ? [data.parentId] : [data.id]
    };
    return Service.device.deviceListByOrganization(searchData);
  };

  /**
   * @desc 分配设备到应用系统/从应用系统解除分配 type update: 分配 ,cancel : 取消分配
   */
  updateOperationCenterDevices = async (type = "update") => {
    const { data } = this.props;
    const { checkedIds, cancelCheckedIds, deviceList, currentList } = this.state;
    let options = { toOrganizationId: null, fromOrganizations: [] };
    const newState = {};
    if (type == "update") {
      const checkedList = deviceList.filter(v => checkedIds.indexOf(v.id) > -1);
      options.toOrganizationId = data.id;
      options.fromOrganizations = checkedIds.map(id => ({ deviceId: id, fromOrganizationId: data.parentId }));
      newState.checkedIds = [];
      newState.deviceList = deviceList.filter(v => checkedIds.indexOf(v.id) === -1);
      newState.currentList = [].concat(currentList, checkedList);
    } else {
      const checkedList = currentList.filter(v => cancelCheckedIds.indexOf(v.id) > -1);
      options.toOrganizationId = data.parentId;
      options.fromOrganizations = cancelCheckedIds.map(id => ({ deviceId: id, fromOrganizationId: data.id }));
      newState.cancelCheckedIds = [];
      newState.currentList = currentList.filter(v => cancelCheckedIds.indexOf(v.id) === -1);
      newState.deviceList = [].concat(deviceList, checkedList);
    }
    this.setState({ loading: true });
    try {
      await Service.device.updateOrganizationDevicesBatch(options);
      newState.tabKey = Math.random();
      this.setState(newState);
      message.success("操作成功！");
    } catch (e) {
      console.error(e);
      message.warn("操作失败！");
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * @desc 未分配设备选择
   */
  onChecked = async checkedIds => {
    return await this.setState({ checkedIds });
  };

  allocatedAll = e => {
    const { deviceList, keywords, placeIds } = this.state;
    const list = this.computedUnsetDevices(deviceList, keywords[0], placeIds[0]);
    let checkedIds = e.target.checked ? list.map(v => v.id) : [];
    this.onChecked(checkedIds).then(() => {
      this.refTable.current.forceUpdateGrid();
    });
  };

  /**
   * @desc 已分配设备选择
   */
  onCancelChecked = async cancelCheckedIds => {
    return await this.setState({ cancelCheckedIds });
  };

  unAllocatedAll = e => {
    const { currentList, keywords } = this.state;
    const list = keywords[1] ? currentList.filter(v => v.deviceName.indexOf(keywords[1]) > -1) : currentList;
    this.onCancelChecked(e.target.checked ? list.map(v => v.id) : []).then(() => {
      this.refTableRight.current.forceUpdateGrid();
    });
  };

  /**
   * @desc 选择场所查询
   */
  onSelect = placeIds => {
    this.setState({ placeIds });
  };

  computedUnsetDevices(list, keywords, placeId) {
    const { place } = this.props;
    const allParentIds = placeId ? place.queryPlaceIdsForParentId(placeId) : [];
    if (!placeId && !keywords) {
      return list;
    }
    const newList = [];
    for (let i = 0, l = list.length; i < l; i++) {
      const flags = new Array(2).fill(false);
      const item = list[i];
      if (keywords) {
        flags[0] = item.deviceName.indexOf(keywords) > -1;
      } else {
        flags[0] = true;
      }
      if (placeId) {
        flags[1] = allParentIds.indexOf(item.placeId) > -1;
      } else {
        flags[1] = true;
      }
      if (flags[0] && flags[1]) {
        newList.push(item);
      }
    }
    return newList;
  }

  render() {
    const { deviceList, currentList, keywords, checkedIds, cancelCheckedIds, loading, placeIds, treeData, tabKey } = this.state;

    const [keywords1, keywords2] = keywords;
    const deviceListPart1 = this.computedUnsetDevices(deviceList, keywords1, placeIds[0]);
    const deviceListPart2 = keywords2 ? currentList.filter(v => v.deviceName.indexOf(keywords2) > -1) : currentList;
    return (
      <div className="list-tb-container list-tb-container-org">
        <Spin spinning={loading}>
          <div className="device-tlt">
            <span className="fl">未分配设备：</span>
            <span className="fr">已分配设备（{currentList.length}个）</span>
          </div>
          <div className={`device-edit-container ${BSConfig.microService.place ? "" : "has-not-place"}`}>
            {BSConfig.microService.place && <PlaceTree onSelect={this.onSelect} activeKey={placeIds} treeData={treeData} />}
            <div className="tb">
              <Table
                ref={this.refTable}
                list={deviceListPart1}
                checkedIds={checkedIds}
                onChecked={this.onChecked}
                key={`${tabKey}-${placeIds[0] ? placeIds[0] : ""}`}
                onChange={this.updateSearchData}
                type="searchData"
              >
                <div className="item th">
                  <div className="name">
                    <Checkbox
                      checked={deviceListPart1.length === checkedIds.length && checkedIds.length > 0}
                      indeterminate={deviceListPart1.length !== checkedIds.length && checkedIds.length > 0}
                      onClick={this.allocatedAll}
                    />
                    设备列表
                  </div>
                </div>
              </Table>
            </div>
            <div className="btn-group">
              <div>
                <Button type="primary" disabled={!!!checkedIds.length} onClick={() => this.updateOperationCenterDevices()}>
                  <IconFont type="icon-S_Arrow_BigRight" />
                  <div>分配</div>
                </Button>
                <Button disabled={!!!cancelCheckedIds.length} onClick={() => this.updateOperationCenterDevices("cancel")}>
                  <IconFont type="icon-S_Arrow_BigLeft" />
                  <div>取消</div>
                </Button>
              </div>
            </div>
            <div className="tb">
              <Table
                ref={this.refTableRight}
                list={deviceListPart2}
                checkedIds={cancelCheckedIds}
                onChecked={this.onCancelChecked}
                onChange={this.updateSearchData}
                key={tabKey}
                type="currentSearchData"
              >
                <div className="item th">
                  <div className="name">
                    <Checkbox
                      checked={deviceListPart2.length === cancelCheckedIds.length && cancelCheckedIds.length > 0}
                      indeterminate={deviceListPart2.length !== cancelCheckedIds.length && cancelCheckedIds.length > 0}
                      onClick={this.unAllocatedAll}
                    />
                    设备列表
                  </div>
                </div>
              </Table>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default DeviceResource;
