import React from 'react';
import './baseInfo.less';
const LabelValue = Loader.loadBaseComponent('LabelValue')
const { departmentType } = Dict.map
export default class HeadTitle extends React.Component {
  render() {
    const { info } = this.props;
    let type = departmentType.find(v => v.value === info.organizationType);
    let label = null;
    type ? (label = type.label) : (label = departmentType[0].label);
    return (
      <div className="org-head-title">
        <div className="orgInfo">
            <LabelValue className="item" label="组织名称" noWrap={true} value={info.name} />
            <LabelValue className="item" label="创建时间" noWrap={true} value={Utils.formatTimeStamp(parseInt(info.createTime, 10))} />
            {/* <LabelValue className="item" label="组织类型" noWrap={true} value={label} /> */}
            <LabelValue className="item item-desc" label="描述" value={info.desc || '-'} />
          </div>
      </div>
    );
  }
}
