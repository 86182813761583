import React from "react";
import { Spin, message, Button, Checkbox } from "antd";
import PlaceTreeSelect from "./components/PlaceTreeSelect";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import SelectList from "./components/PlaceSelectList";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");

@withRouter
@Decorator.businessProvider("place")
class OperationCenterDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      currentList: [],
      checkedKeys: [],
      loading: false,
      treeData: [],
      deleteSelectKeys: [],
      keywords: ""
    };
  }

  componentWillMount() {
    this.getOperationPlaces();
  }
  deleteSelectPlaces = keys => {
    this.setState({ deleteSelectKeys: keys });
  };

  upDateSelectList = searchData => {
    this.setState({ keywords: searchData.keywords });
  };

  getOperationPlaces = () => {
    const { data } = this.props;
    Promise.all([
      Service.organization.queryPlacesByOrganizationId({ organizationId: data.parentId }),
      Service.organization.queryPlacesByOrganizationId({ organizationId: data.id })
    ]).then(res => {
      const [placesRes, currentPlacesRes] = res;
      const devices = BaseStore.device.queryCameraByIncludeOrgId(data.id);
      const placeIdsByDevices = devices.map(v => v.placeId);
      const places = _.orderBy(placesRes.data, ["id"], ["asc"]) || [];
      const currentPlaces = currentPlacesRes.data || [];
      const allPlacesCodes = [];
      const areaCodesByDevice = [];
      places.forEach(v => {
        allPlacesCodes.push(v.areaCode);
        if (placeIdsByDevices.indexOf(v.id) > -1) {
          areaCodesByDevice.push(v.areaCode);
        }
        v.placeId = v.id;
        v.id = v.areaCode;
        v.name = v.areaName;
        v.parentId = v.parentCode;
      });
      const defaultCheckedIds = areaCodesByDevice;
      this.setState({
        checkedKeys: defaultCheckedIds,
        currentList: currentPlaces,
        list: places,
        treeData: Utils.computPlaceTree(places)
      });
    });
  };

  /**
   * @desc 场所树选择
   */
  onCheck = async checkedKeys => {
    return await this.setState({ checkedKeys: checkedKeys });
  };

  /**
   * @desc 确认分配 type=confirm 分配  cancle 取消分配
   */
  submit = async (type = "confirm") => {
    let { checkedKeys, deleteSelectKeys, list, currentList } = this.state;
    const { data } = this.props;
    let deleteProvinceIds = [];
    let addPlaceIds = [];
    const options = {};
    if (type == "cancel") {
      deleteProvinceIds = deleteSelectKeys;
      const delItems = _.cloneDeep(currentList.filter(v => deleteSelectKeys.indexOf(v.id) > -1));
      options.currentList = _.differenceBy(currentList, delItems, "id");
      options.deleteSelectKeys = [];
      delItems.forEach(v => {
        v.placeId = v.id;
        v.id = v.areaCode;
        v.parentId = v.parentCode;
      });
      options.list = _.orderBy([].concat(list, delItems), ["placeId"], ["asc"]);
      options.treeData = Utils.computPlaceTree(options.list);
    } else {
      const addPlaces = _.cloneDeep(checkedKeys.map(id => list.find(v => v.id === id)));
      addPlaceIds = addPlaces.map(v => {
        v.id = v.placeId;
        return v.id;
      });
      options.checkedKeys = [];
      options.currentList = [].concat(currentList, addPlaces);
      options.list = _.orderBy(_.differenceBy(list, addPlaces, "placeId"), ["placeId"], ["asc"]);
      options.treeData = Utils.computPlaceTree(options.list);
    }
    this.setState({ loading: true });
    try {
      await Service.organization.updateOrganizationPlaces({
        organizationId: data.id,
        addPlaceIds,
        deletePlaceIds: deleteProvinceIds
      });
      message.success("分配成功");
      this.setState(options);
    } catch (e) {
      message.error("分配失败!");
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { keywords, checkedKeys, loading, currentList = [], treeData, deleteSelectKeys } = this.state;
    const list = keywords ? currentList.filter(v => v.areaName.indexOf(keywords) > -1) : currentList
    return (
      <div className="org-place-resource-container">
        <Spin spinning={loading}>
          <div className="place-edit-container">
            <PlaceTreeSelect
              title={<span className="device-tlt">全部场所：</span>}
              onCheck={this.onCheck}
              currentList={currentList}
              checkedKeys={[].concat(checkedKeys)}
              submit={this.submit}
              treeData={treeData}
            />
            <div className="btn-group">
              <div>
                <Button type="primary" disabled={checkedKeys.length === 0} onClick={() => this.submit()}>
                  <IconFont type="icon-S_Arrow_BigRight" />
                  <div>分配</div>
                </Button>
                <Button disabled={deleteSelectKeys.length === 0} onClick={() => this.submit("cancel")}>
                  <IconFont type="icon-S_Arrow_BigLeft" />
                  <div>取消</div>
                </Button>
              </div>
            </div>
            <div style={{ height: "100%" }}>
              <span className="device-tlt">已分配场所（{currentList.length}个）</span>
              <div className="place-tree-list">
                <div className="org-place-list">
                  <SelectList
                    checkedList={deleteSelectKeys}
                    onChange={this.deleteSelectPlaces}
                    searchChange={searchData => this.upDateSelectList(searchData)}
                    list={list}
                    key={list.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
export default OperationCenterDevice;
