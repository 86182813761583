import React from "react";
import { Checkbox } from "antd";
import "./index.less";
import SearchGroup from "../Search";

const CheckboxGroup = Checkbox.Group;
const List = Loader.loadBaseComponent("ReactVirtualized", "SimpleList");
const DeviceIcon = Loader.loadBaseComponent("DeviceIcon");

export default class TableView extends React.Component {
  constructor(props) {
    super(props);
    this.refTb = React.createRef();
  }

  state = {
    indeterminate: false
  };
  componentDidMount = () => {};
  /**
   * @desc 更新List
   */
  forceUpdateGrid = () => {
    this.refTb.current && this.refTb.current.forceUpdateGrid();
  };

  /**
   * @desc 全选
   */
  checkAll = e => {
    const { onChecked, list } = this.props;
    // this.setState({indeterminate: false})
    onChecked(e.target.checked ? list.map(v => v.id) : []).then(() => this.forceUpdateGrid());
  };

  /**
   * @desc 勾选
   */
  onChecked = ids => {
    const { onChecked } = this.props;
    onChecked(ids).then(() => this.forceUpdateGrid());
  };

  /**
   * @desc 修改查询条件
   */
  onChange = options => {
    const { onChange, type } = this.props;
    onChange(type, options);
  };

  render() {
    const { list, checkedIds = [] } = this.props;
    return (
      <div className="d-sollot-tb-container">
        <div className="list-container">
          {this.props.children}
          <SearchGroup onChange={this.onChange} />
          <CheckboxGroup onChange={this.onChecked} value={checkedIds}>
            {!!list.length && (
              <List
                data={list}
                ref={this.refTb}
                rowHeight={30}
                renderItem={item => (
                  <div className="item">
                    <div className="name" title={item.deviceName}>
                      <Checkbox value={item.id} />
                      <DeviceIcon type={item.deviceType} status={item.deviceStatus} />
                      {item.deviceName}
                    </div>
                  </div>
                )}
              />
            )}
          </CheckboxGroup>
        </div>
      </div>
    );
  }
}
