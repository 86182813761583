import React from "react";
import { Button, Checkbox } from "antd";
import SearchGroup from "../Search";
import "./index.less";
import "./index.less";

const CheckboxGroup = Checkbox.Group;
const IconFont = Loader.loadBaseComponent("IconFont");

class PlaceSelectList extends React.Component {
  onChange = checkedValues => {
    this.props.onChange(checkedValues);
  };
  onCheckAllChange = e => {
    const { list } = this.props;
    this.props.onChange(e.target.checked ? list.map(v => v.id) : []);
  };
  render() {
    const { list, searchChange, checkedList } = this.props;

    return (
      <div className="place-select-right">
        <div className="th">
          <Checkbox
            indeterminate={list.length !== 0 && checkedList.length !== list.length && checkedList.length > 0}
            onChange={this.onCheckAllChange}
            checked={list.length > 0 && checkedList.length > 0 && list.length === checkedList.length}
          />
          场所列表
        </div>
        <div className="place-select-list">
          <SearchGroup onChange={searchChange} />
          <div className="place-list">
            <CheckboxGroup onChange={this.onChange} value={checkedList}>
              {list.map(v => {
                return (
                  <Checkbox value={v.id}>
                    <div className="p-item">
                      <IconFont type="icon-L_Place_Place" />
                      {v.areaName}
                    </div>
                  </Checkbox>
                );
              })}
            </CheckboxGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default PlaceSelectList;
