import React from "react";
import "./index.less";
import { Button } from "antd";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";

const Tree = Loader.loadBusinessComponent("PlaceTree");
// const Tree = Loader.loadBaseComponent('PlaceComponents', 'PlaceTree')
@withRouter
@Decorator.businessProvider("place", "deviceManagement")
class PlaceTree extends React.Component {
  render() {
    const { checkedKeys = [], onCheck, title = "", disableKeys, treeData } = this.props;
    return (
      <div className="place-tree-select select-container">
        {title}
        <Tree
          checkable={true}
          hasTitle={true}
          title="场所列表"
          checkedKeys={checkedKeys}
          onCheck={onCheck}
          disableKeys={disableKeys}
          treeData={treeData}
          selfTreeData={true}
        />
      </div>
    );
  }
}

export default PlaceTree;
